import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/layout/Layout';
import Car from '../../assets/animated.svg';

const ThankYou = () => {
  return (
    <Layout>
      <div className="container mx-auto px-8 text-center my-20">
        <Car className="w-6/12 mx-auto mt-16" />
        <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold text-blue-500 leading-none mt-24">
          Vielen Dank
        </h1>
        <p className="text-xl lg:text-2xl mt-3">Deine Buchung war erfolgreich!</p>
        <p className="mt-3 mb-8">
          Schau in dein E-Mail Postfach, falls du keine Email erhalten hast ist deine Buchung
          dennoch eingegangen. <br /> Du kannst dich jederzeit auch im Chat an uns wenden!
        </p>
        <Link
          className="mb-64 button py-3 px-8 button bg-primary hover:bg-primary-darker rounded text-white button hover:text-white"
          to="/"
        >
          Zur Startseite
        </Link>
      </div>
    </Layout>
  );
};

export default ThankYou;
